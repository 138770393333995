import React from "react";

import { Title, EnterTitle } from "components";

import styles from "./what-we-do.module.scss";

export const WhatWeDo: React.FC = () => {
  return (
    <div className={styles.container} id="what-we-do">
      <section className={styles.content}>
        <Title>What we do</Title>
        <p className={styles.text}>
          Medinetwork enables secure and cost-efficient data exchange while keeping their full integrity
          and confidentiality.
          <br />
          <br />
          MediNetwork provides security, scalability and everything-at-once as Software-as-a-Service or On Premise platform.
        </p>
        <EnterTitle section="#contact">Contact us</EnterTitle>
      </section>
      <section className={styles.details} />
    </div>
  );
};
