import React from "react";

import { Header } from "components";

import background from "assets/images/background.jpeg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import styles from "./banner.module.scss";

interface Props {
  navigateTo?: string;
}

export const Banner: React.FC<Props> = ({ navigateTo }) => {
  const navigateToSection = () => {
    window.location.href = navigateTo || "#what-we-do";
  };

  return (
    <div className={styles.container}>
      <img src={background} alt="Planet" className={styles.background} />
      <div className={styles.overlay} />
      <div className={styles.content}>
        <Header />
        <section className={styles.innerContent}>
          <h2 className={styles.title}>
            Data <br />
            Trading
          </h2>
          <p className={styles.description}>
            Software Platform for trading data in the highly-regulated sectors like Life Science, insurance and
            Space by using scalable, secure decentralized marketplace on blockchain.
          </p>
        </section>
      </div>
    </div>
  );
};
